'use client'

import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'

import { createCookieManager, cookiesDataByName } from '@marketplace-web/shared/cookies'
import { clientSideMetrics } from '@marketplace-web/shared/metrics'
import { initialiseMonitoring } from 'startup/monitoring'
import { PageId } from 'types/page-id'
import { checkConsentGroup } from '@marketplace-web/shared/consent'
import { ConsentGroup } from 'constants/consent'

initialiseMonitoring()

type Props = {
  pageId: PageId
}

const Monitoring = ({ pageId }: Props) => {
  useEffect(() => {
    datadogRum.setGlobalContextProperty('page', pageId)
    clientSideMetrics.counter('page_load', { page: pageId }).increment()
  }, [pageId])

  useEffect(() => {
    const handleBannerLoaded = () => {
      const optanonConsentCookie = createCookieManager().get(cookiesDataByName.OptanonConsent)
      const isPerformanceCookiesEnabled = checkConsentGroup(
        ConsentGroup.Performance,
        optanonConsentCookie,
      )

      datadogRum.setTrackingConsent(isPerformanceCookiesEnabled ? 'granted' : 'not-granted')
    }

    window.addEventListener('bannerLoaded', handleBannerLoaded)

    return () => window.removeEventListener('bannerLoaded', handleBannerLoaded)
  }, [])

  return null
}

export default Monitoring
